@import "../../index.scss";
.power_screen{
  .header_container {
    min-height: 100px;
    margin-bottom: 2em;
    .head {
      color: $text;
    }
    .header_wrapper {
      display: flex;
      align-items: center;
      .search_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        padding-right: 0;
        align-items: center;
        .add_user {
          padding: 15px;
          background-color: #6c5dd3;
          border-radius: 16px;
          color: white;
        }
      }
      .header_details_wrapper {
        display: flex;
        align-items: center;
        min-width: max-content;
        .back_image{
          cursor: pointer;
        }
        .header_image_container {
          width: 100px;
          height: 100px;
          margin: 20px;
          .header_image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .header_details {
          .organization_name {
            font-size: 24px;
            font-weight: 600;
          }
          .organization_id {
            font-size: 16px;
            font-weight: 600;
            color: $text-light;
          }
          .organization_contact_wrapper {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .power_filters_scroll{
    white-space: nowrap;
    overflow: scroll;;
  }
  .power-cards{
    width: 250px;
    padding: 15px 20px;
    background-color: $white;
    display: inline-flex;
    position: relative;
    border-radius: $uiBorderRadius;
    align-items: center;
    margin-top: 12px;
    margin-right: 15px;
    cursor: pointer;
    &.selected{
      background-color: $primary;
      color: white;
    }
    .content{
      flex: 1;
      p{
        margin-bottom: 2px;
      }
      .big-content{
        font-size: 20px;
      }
      .small-content{
        font-size: 15px;
      }
    }
    .icon{
      font-size: 45px;
    }
    .action-btn{
      position: absolute;
      top: -10px;
      right: -10px;
      background: $primary;
      padding: 5px;
      border-radius: 20px;
      border: 3px solid white;
      img{
        filter: brightness(0) invert(1);;
      }
    }
  }
}