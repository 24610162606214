@import "../../index.scss";


.ticket_screen{
  position: relative;
  .header-right{
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .priority-legend{
      display: flex;
      align-items: center;
      .high{
        display: inline-block;
        width: 50px;
        margin: 0 10px;
        border: 3px solid $highPriorityColor;
      }
      .medium{
        display: inline-block;
        width: 50px;
        margin: 0 10px;
        border: 3px solid $mediumPriorityColor;
        
      }
      .low{
        display: inline-block;
        width: 50px;
        margin: 0 10px;
        border: 3px solid $lowPriorityColor;

      }
    }
  }
  .board-container{
    background-color: $white;
    border-radius: $uiBorderRadius;
    padding: 20px;
    white-space: nowrap;
    overflow: auto;
    position: relative;
    .board{
      height: 700px;
      width: 300px;
      margin-right: 20px;
      display: inline-block;
      background-color: $background;
      border: 1px solid $border;
      overflow-x: scroll;
      position: relative;
      border-radius: $uiBorderRadiusThin;
      
    }
    .board-header{
      position: sticky;
      top: 0;
      padding: 10px;
      border-bottom: 1px solid $border;
      background-color: $white;
      // box-shadow: 0px 6px 9px -1px #00000036;
      font-size: 20px;
      font-weight: 500;
      z-index: 10;
    }
    .board-body{
      white-space: wrap;
      padding: 10px;
      padding-bottom: 0px;
      height: 100%;
    }
  }
  .ticket-container{
    border-radius: $uiBorderRadiusThin;
    overflow: hidden;
    border: 1px solid $border;
    background: $white;
    padding: 10px;
    margin-bottom: 10px;
    transition: 0.3s all;
    position: relative;
    padding-left: 20px;
    cursor: grab;
    transition: 0.5s all;
    &::after{
      content: " ";
      height: calc(100% + 2px);
      top: -1px;
      left: 0px;
      width: 10px;
      background-color: $border;
      position: absolute;
    }
    .priority-meter{
      height: calc(100% + 2px);
      background-color: #C01717;
      width: 10px;
      position: absolute;
      top: -1px;
      left: 0px;
      z-index: 1;
      transition: 0.5s all;
      &.processing{
        transition: 0.5s all;
        height: 50%;
      }
      &.closed{
        transition: 0.5s all;
        height: 0%;
      }
    }
    &:hover{
      transition: 0.3s all;
      box-shadow: 0px 6px 9px -1px #00000036;
      // transform: scale(1.05);
    }
    .ticket-header{
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .ticket-content {
      &.muted{
        font-size: 12px;
        color: black;
      }
    }
    
  }
}

.ticket-view-screen{
  .ticket-view-heading{
    border-left : 30px solid $highPriorityColor;
    box-shadow: 0px 6px 9px -1px #00000036; 
    background-color: $white;
    width: calc(100% + 84px);
    position: relative;
    left: -47px;
  }
  .avatar{
    margin: 20px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: $primary;
    
  }
  .tabs{
    display: flex;
    align-self: flex-end;
    justify-content: end;
    // border : 1px solid $border;
  }
  .tab-item{
    // flex: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border : 1px solid $border;
    cursor: pointer;
    &.selected{
      box-shadow: 0px 6px 9px -1px #00000036; 
    }
  }
  .ticket-history-container{
    padding: 40px 20px;
    background-color: rgba(217, 217, 217, 0.21);
    border: 0.5px solid #80819155;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
  } 
  .hist-rows{
    padding: 20px;
    border-radius: $uiBorderRadius;
    font-weight: 600;
    background-color: $white;
    margin: 20px 0;
  }
  .history-details-container{
    background-color: $white;
    padding: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
    .ticket-history-status{
      font-size: 10px;
      display: flex;
      font-weight: 500;
      align-items: center;
      gap: 10px;
    }
    .ticket-history-details-row{
      background-color: $border;
      display: inline-block;
      padding: 10px;
      border-radius: $uiBorderRadiusThin;
    }
  }
  .status-indicator{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    &.pending{
      background-color: $highPriorityColor;
    }
    &.processing{
      background-color: $primary;
    }
    &.closed{
      background-color: #659447;
    }
    &.client{
      background-color: $mediumPriorityColor;
    }
  }
  .overview-box{
    padding: 10px;
    border-radius: $uiBorderRadiusThin;
    color: white;
    display: inline-block;
    margin-right:5px;
    &.pending{
      background-color: $highPriorityColor;
    }
    &.processing{
      background-color: $primary;
    }
    &.closed{
      background-color: #659447;
    }
    &.client{
      background-color: $mediumPriorityColor;
    }
  }
}
