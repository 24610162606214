@import "../../index.scss";
.add_power_container{
  padding: 20px;
  background-color: $white;
  border-radius: $uiBorderRadius;
  .p-dropdown {
    border: 1px solid $border !important;
    border-radius: $uiBorderRadiusThin;
  }
  .input-label{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  .custom-upload{
    background: $primary;
    padding: 10px;
    border-radius: $uiBorderRadiusThin;
    color: $white;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  .year_dropdown {
  
  }
  .add_power_wrapper{
    border : 1px solid $border;
    padding: 20px;
    margin-bottom: 20px;
  }
  .add_remove {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    .plus {
      border: 1px solid grey;
      border-radius: 50%;
      padding: 0.2rem 0.5rem;
      margin-right: 10px;
    }
    button {
      border: none;
      background-color: transparent;
      font-size: 15px;
    }
  }

}
