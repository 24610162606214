@import "../../../index.scss";

.input_container {
  .input {
    width: 100%;
    height: $uiHeight;
    border: $uiBorder solid $border;
    border-radius: $uiBorderRadius;
    padding-left: 1em;
    outline: none;
    // background-color: $border;
  }
  .input_textarea {
    height: 100px;
    padding: 0.5em;
  }
  .input:focus {
    border: $uiBorder solid $primary;
    border-radius: $uiBorderRadius;
    background-color: $white;
  }
  .input_wide {
    width: 98%;
  }
  .input_short {
    width: fit-content;
  }
  .map-pick-box {
    width: 100%;
    min-height: $uiHeight;
    border: $uiBorder solid $border;
    border-radius: $uiBorderRadius;
    padding-left: 1em;
    // background-color: $border;
    display: flex;
    align-items: center;
    color: $text-light;
  }
  .label_container {
    color: $text;
    padding: 0.5em 0em;
    .required {
      color: red;
    }
  }
  .date-picker {
    width: 100%;
    height: $uiHeight;
    border: $uiBorder solid $border;
    border-radius: $uiBorderRadius;
    padding-left: 1em;
    outline: none;
    // background-color: $border;
  }
  .field_wrap {
    .password_wrap {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-radius: $uiBorderRadius;
      outline: none;
      background-color: $border;
      position: relative;

      .pwd_icon {
        position: absolute;
        right: 0;
        padding-top: 15px;
        padding-right: 10px;
      }
    }
  }
}
.react-datepicker-wrapper {
  display: block !important;
}
.select_container .select_label {
  color: $text !important;
  font-weight: 500 !important;
}
.select_address_container .input_container .label_container {
  color: $text !important;
}
.all_filter_dropdown_wrapper{
  .date-picker {
    width: 100%;
    padding: 13px 20px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 30px;
    padding-left: 1em;
    outline: none;
    // background-color: $border;
  }
  .p-calendar .p-inputtext {
    flex: 1 1 auto;
    width: 1%;
    border-radius: 25px;
    padding: 10px 20px;
  }
}