@import "../../index.scss";

.view_container {
  background-color: $white;
  border-radius: 24px;

  .view_wrapper {
    .view_head_container {
      .view_head_wrapper {
        display: flex;
        padding: 2em 1em;

        .view_head_right_container {
          flex: 0.2;
          display: flex;
          justify-content: flex-end;
          padding-right: 2em;

          .view_head_nav_icon_wrapper {
            display: flex;
            justify-content: center;

            .view_head_nav_icon {
              padding-right: 1em;
            }
          }
        }
      }
    }

    .view_field_body_container {
      .view_field_body_wrapper {
        margin: 1em;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;
        padding: 10px;

        .view_field_container {
          margin: 1em;
          min-width: 200px;
          max-width: 250px;

          .view_field_label {
            color: $text;

            .a {
              padding-left: 10%;
            }
          }

          .view_file {
            width: 150px;
            height: 90px;
            object-fit: cover;
            border-radius: 8px;
            cursor: pointer;
          }
        }

        .title_container {
          margin-left: 15px;
          margin-top: 20px;
          font-size: 16px;
        }
      }
    }

    .editor_view_wrapper {
      margin: 0 calc(2em + 10px);
      margin-top: -10px;
      padding-bottom: 20px;
      .content-wrapper{
        display: flex;
        // justify-content: center;
      }
      .editor_content {
        margin-top: 30px;
        background: #f6f6f6;
        padding: 30px;
        box-shadow: 0 0 7px 2px #00000021;
        width: 100%;
      }
      ol,
      ul {
        padding-left: 2rem;
      }
    }
  }
}