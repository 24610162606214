@import "./index.scss";

.p-multiselect .p-multiselect-label {
  padding: 15px;
  font-size: 14px;
}

.p-dropdown .p-dropdown-label {
  padding: 10px;
  font-size: 14px;
}

.p-multiselect {
  border: 2px solid $border !important;
  border-radius: $uiBorderRadius;
}

.p-dropdown {
  border: 2px solid $border !important;
  // border-radius: $uiBorderRadius;
}


ol,
ul {
  padding-left: 0;
}

.dropdown-primary {
  z-index: 10;

  .p-dropdown {
    background-color: $primary;
    border-radius: 5px !important;
    overflow: hidden;
  }

  .p-placeholder {
    background-color: $primary;
    color: white !important;
  }

  .p-dropdown .p-dropdown-label {
    padding: 5px 10px;
    color: $white;
    text-align: center;
  }

  svg.p-icon.p-dropdown-trigger-icon.p-clickable {
    color: $white
  }
  }.p-inputtext{
    font-size: 0.9rem;
}

  // .p-datepicker table td{
  //   padding: 5px;
  // }