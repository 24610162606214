@import "../../index.scss";

.add_container {
  background-color: $white;
  border-radius: 24px;
  .add_wrapper {
    margin: 1em 0em;
    .add_head_container {
      .title_container {
        font-size: 24px;
        font-weight: 600;
        padding: 1em;
      }
      .add_head_wrapper {
        display: flex;
        padding: 2em 1em;
        .add_head_right_container {
          flex: 0.2;
          display: flex;
          justify-content: flex-end;
          padding-right: 2em;
          .add_head_nav_icon_wrapper {
            display: flex;
            justify-content: center;
            .add_head_nav_icon {
              padding-right: 1em;
            }
          }
        }
      }
    }
    .add_field_body_container {
      .add_field_body_wrapper {
        margin: 1em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding: 10px;
        align-items: flex-start;

        .supervisor {
          display: block;
          background-color: #e4e4e4;
          border: 1px solid grey;
          border-radius: 20px;
          padding: 15px;
          margin: 10px;
          .add_remove {
            margin: 20px;
            display: flex;
            justify-content: space-between;
            .plus {
              border: 1px solid grey;
              border-radius: 50%;
              padding: 0.2rem 0.5rem;
              margin-right: 10px;
            }
            button {
              border: none;
              background-color: transparent;
              font-size: 15px;
            }
          }
        }
        .add_field_checkbox__container {
          align-items: center;
          display: flex;
          gap: 10px;
          padding-left: 18px;
          font-size: 20px;
          color: $text-light;
          padding-top: 20px;
        }
        .power {
          display: flex;
        }
        .capacity {
          display: flex;
          gap: 20px;
          align-items: center;
          .area {
            height: $uiHeight;
            padding-top: 2em;
            outline: none;
            font-weight: bolder;
            font-size: larger;
          }
        }
        .add_field_container {
          margin: 0em 1em;
          min-width: 200px;
          .verified-checkbox {
            color: green;
            margin-top: 10px;
          }
          .invalid-checkbox {
            color: red;
          }
          .view_field {
            img {
              width: 200px;
              border-radius: 10%;
            }
          }
          .additional_field {
            font-size: 12px;
            padding-top: 10px;
          }
          .add_field_label {
            color: $text;
          }
          .add_file {
            width: 150px;
            height: 90px;
            object-fit: cover;
            border-radius: 8px;
          }
          .add_field_container_img {
            width: 200px;
          }
        }
        .title_container {
          margin-left: 15px;
          margin-top: 20px;
          font-size: 16px;
        }
        
      }
      .editor_container {
        margin: 2em;
        margin-top: -20px;
        padding: 10px;
      }
    }
  }
  .remarks_wrapper {
    height: 55px;
  }
}
.file-label {
  color: $text ;
  font-weight: 500 ;
  font-size: 12px;
  margin-bottom: 5px;
}
.file-input-container {
  padding: 10px;
  border: $uiBorder solid $border;
  border-radius: $uiBorderRadius;
  // display: flex;
  // align-items: center;
  cursor: pointer;
  .file-show {
    height: 34px;
    width: 34px;
    border-radius: 10px;
    background-color: $border;
    margin-right: 10px;
    padding: 5px;
    overflow: hidden;
    &.show {
      padding: 0;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .choose-btn {
    border: $uiBorder solid $primary;
    color: $primary;
    border-radius: $uiBorderRadius;
    padding: 5px 10px;
    margin-right: 10px;

  }
  .file-placeholder {
    color: $text;
    font-size: 12px;
    flex: 1;
  }
  .file-viewer{
    width: 100%;
    height: 300px;
    padding: 10px;
    margin-top: 10px;
    border-top: $uiBorder solid $border;
    cursor: pointer;
    img{
      width:  100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
