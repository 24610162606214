@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "./index.scss";
* {
  box-sizing: border-box;
  outline: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
  font-family: 'Poppins', 'System-ui',sans-serif;
}
body{
  zoom: 75%;
}
@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11142d;
  font-size: 14px;
  font-weight: 400;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bg-primary{
  background: $primary !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.h1 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 600;
}
.h2 {
  font-size: 48px;
  font-weight: 600;
}
.h3 {
  font-size: 40px;
  font-weight: 600;
}
.h4 {
  font-size: 32px;
  font-weight: 500;
}
.h5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}
.h6 {
  font-size: 18px;
  font-weight: 500;
}

.menu {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.select-options {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    background-color: $primary;
    color: $white;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.d1 {
  font-size: 96px;
  font-weight: 600;
}
.d2 {
  font-size: 72px;
  font-weight: 600;
}
.d3 {
  font-size: 64px;
  font-weight: 600;
}
.caption1 {
  font-size: 13px;
  font-weight: 600;
}
.caption2 {
  font-size: 14px;
  font-weight: 500;
}
.body1 {
  font-size: 13px;
  font-weight: 400;
}
.body2 {
  font-size: 13px;
  font-weight: 400;
}
.button {
  font-size: 14px;
  font-weight: 500;
}
.button2 {
  font-size: 13px;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.pd1 {
  padding: 1em;
}
.pd2 {
  padding: 2em;
}
.pdl1 {
  padding-left: 1em;
}
.pdr1 {
  padding-right: 1em;
}
.pdl2 {
  padding-left: 2em;
}
.pdr2 {
  padding-right: 2em;
}
.all_filter_container {
  margin-top: 20px;
  margin-bottom: 35px;
  background-color: white;
  .all_filter_heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: #11142d;
  }
  .all_filter_dropdown_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap !important;
    flex-flow: row;
    gap: 25px;
    background-color: white;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #e8e8e8;
  }
  .all_filter_dropdown_item {
    min-width: 100px;
  }
}
.search_wrapper {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  button{
    width: 200px !important;
  }
}
.button_group {
  display: flex;
  gap: 10px;
}
.view-action-req {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 200px;
  border-radius: 24px;
}
.avoid-clicks{
  pointer-events: none;
  opacity: 0.5;
}

